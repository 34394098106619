* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@font-face {
  font-family: "inter-regular";
  src: url("/fonts/Inter_18pt-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "inter-bold";
  src: url("/fonts/Inter_18pt-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "clashDisplay-regular";
  src: url("/fonts/ClashDisplay-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "clashDisplay-medium";
  src: url("/fonts/ClashDisplay-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "clashDisplay-semibold";
  src: url("/fonts/ClashDisplay-Semibold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "clashDisplay-bold";
  src: url("/fonts/ClashDisplay-Bold.ttf");
  font-weight: 700;
}
/* font Face = tf  */
/* color face = cl */
/* sizing = ss  */
:root {
  /* Typo graphy  */
  --ff-inter-regular: "inter-regular";
  --ff-inter-bold: "inter-bold";
  --ff-clashDisplay-regular: "clashDisplay-regular";
  --ff-clashDisplay-medium: "clashDisplay-medium";
  --ff-clashDisplay-semibold: "clashDisplay-semibold";
  --ff-clashDisplay-bold: "clashDisplay-bold";
  /* Color  */
  --cf-primary-400: #717342;
  --cf-secondary-400: #f0eee8;
  --cf-neutral-black-900: #000000;
  --cf-neutral-gray-400: #737373;
  --cf-neutral-300: #999999;
  --cf-neutral-200: #e6e6e6;
  --cf-neutral-white-100: #ffffff;
  --cf-error-green: #7da258;
  --cf-error-red: #bd4646;
  /* Size */
  /* pc  */
  /* basic  */
  --ss-global-container: 0% 5%;
  /* margin spacing  */
  --ss-ms-xs: 0.5rem;
  --ss-ms-s: 1rem;
  --ss-ms-r: 1.5rem;
  --ss-ms-l: 3rem;
  --ss-ms-xl: 9rem;
  /* padding spacing  */
  --ss-ps-xl: 2.5rem;
  --ss-ps-l: 1.5rem;
  --ss-ps-r: 1rem;
  --ss-ps-s: 0.5rem;
  /* tab  */
  /* margin spacing  */
  --ss-ms-xl-t: 6rem;
  --ss-ms-l-t: 2.5rem;
  /* phone  */
  /* margin spacing  */
  --ss-ms-xl-p: 4rem;
  --ss-ms-l-p: 2rem;
  --ss-ms-r-p: 1.25rem;
  /* padding spacing  */
  --ss-ps-l-p: 1.25rem;
  --ss-ps-xl-p: 1.5rem;
}

/* Utility class */
.container {
  padding: var(--ss-global-container);
}
.section-spacing {
  margin-top: var(--ss-ms-xl);
  margin-bottom: var(--ss-ms-xl);
}
.primary-gradient {
  background: linear-gradient(180deg, #717342 0%, #717342 43.5%, #959855 100%);
}

/* PC typography  */
.display {
  font-family: var(--ff-clashDisplay-bold);
  color: var(--cf-neutral-white-100);
  font-size: 20.5vw;
  line-height: 100%;
}
h1 {
  font-family: var(--ff-clashDisplay-regular);
  color: var(--cf-primary-400);
  font-size: 5vw;
  line-height: 100%;
}
h2 {
  font-family: var(--ff-clashDisplay-semibold);
  color: var(--cf-primary-400);
  font-size: 3rem;
  line-height: 110%;
}
h3 {
  font-family: var(--ff-clashDisplay-semibold);
  color: var(--cf-neutral-white-100);
  font-size: 1.5rem;
  line-height: 120%;
}
h4 {
  font-family: var(--ff-clashDisplay-semibold);
  color: var(--cf-neutral-black-900);
  font-size: 3.75rem;
  line-height: 100%;
}
p {
  font-family: var(--ff-inter-regular);
  color: var(--cf-neutral-black-900);
  font-size: 1rem;
  line-height: 120%;
}

.label-1 {
  font-family: var(--ff-clashDisplay-medium);
  color: var(--cf-neutral-black-900);
  font-size: 1rem;
  line-height: 120%;
}
.label-2 {
  font-family: var(--ff-inter-bold);
  color: var(--cf-neutral-black-900);
  font-size: 1rem;
  line-height: 120%;
}
.label-3 {
  font-family: var(--ff-inter-regular);
  color: var(--cf-neutral-black-900);
  font-size: 2rem;
  line-height: 100%;
}
.label-4 {
  font-family: var(--ff-clashDisplay-semibold);
  color: var(--cf-primary-400);
  font-size: 8rem;
  opacity: 0.1;
  line-height: 0%;
  transform: translateY(-52px);
  margin-bottom: var(--ss-ms-r);
}

button {
  background-color: var(--cf-primary-400);
  font-family: var(--ff-clashDisplay-medium);
  color: var(--cf-neutral-white-100);
  font-size: 1rem;
  line-height: 120%;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  margin: 5px;
  transition: 300ms;
}
button:hover {
  -webkit-box-shadow: 0px 5px 0px 0px #e6ea95;
  -moz-box-shadow: 0px 5px 0px 0px #e6ea95;
  box-shadow: 0px 5px 0px 0px #e6ea95;
  transform: translateY(-5px);
}
button:active {
  background-color: var(--cf-neutral-white-100);
  color: var(--cf-primary-400);
  -webkit-box-shadow: 0px 0px 0px 0px var(--cf-neutral-black-900);
  -moz-box-shadow: 0px 0px 0px 0px var(--cf-neutral-black-900);
  box-shadow: 0px 0px 0px 0px var(--cf-neutral-black-900);
  transform: translateY(0px);
  border: 1px solid var(--cf-primary-400);
  transition: 100ms;
}

/* Regular CSS  */
body,
html {
  background-color: var(--cf-secondary-400);
}

/* nav list styleing  */
ul,
li {
  display: inline;
  list-style: none;
  margin-right: var(--ss-ms-s);
}
a {
  color: var(--cf-neutral-white-100);
  text-decoration: none;
  font-family: var(--ff-inter-regular);
}

nav {
  height: min-content;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 4;
  margin-top: -1.5rem;
}
/* nav icon  */
#nav-icon4 {
  display: none;
}
#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 60px;
  height: 45px;
  position: relative;
  margin-right: 5%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: var(--cf-neutral-white-100);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
/* Icon 4 */

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}

/* nav icon ends */
.background-blur {
  position: absolute;
  z-index: 3;
  height: 15rem;
  width: 100%;
  /* frosted glass BG */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.1)
  );

  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-mask-image: linear-gradient(to bottom, black 10%, transparent 80%);
  mask-image: linear-gradient(to bottom, black 10%, transparent 80%);
}

#png {
  position: absolute;
  z-index: 2;
  width: 100vw;
}
.temp-header .display {
  display: inline-block;
  position: absolute;
  z-index: 1;
  transform: translateY(50%);
  text-align: center;
}
#bg {
  position: relative;
  top: 0;
  width: 100vw;
  background-color: red;
}
.temp-header {
  position: relative;
}
.gradient-transition {
  position: absolute;
  bottom: 0;
  z-index: 4;
  height: 6rem;
  width: 100%;
  background: rgb(240, 238, 232);
  background: -moz-linear-gradient(
    0deg,
    rgba(240, 238, 232, 1) 0%,
    rgba(240, 238, 232, 1) 25%,
    rgba(255, 236, 69, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(240, 238, 232, 1) 0%,
    rgba(240, 238, 232, 1) 25%,
    rgba(255, 236, 69, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(240, 238, 232, 1) 0%,
    rgba(240, 238, 232, 1) 25%,
    rgba(255, 236, 69, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0eee8",endColorstr="#ffec45",GradientType=1);
}

/* Header Styling Starts */
header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.slide-active {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slide-next {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-next .slide-next-img {
  width: 250px;
  height: 350px;
  clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
}

.slider-content {
  position: absolute;
  left: 2em;
  bottom: 0em;
  width: 90%;
  height: 175px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  overflow: hidden;
  display: inline-block;
}

.slider-content-active {
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.slider-content-next {
  position: absolute;
  top: 200px;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

header h3 {
  text-transform: uppercase;
  font-family: var(--ff-clashDisplay-bold);
  font-size: 150px;
  font-weight: lighter;
  color: #fff;
  z-index: 6;
}

h3 span {
  position: relative;
}

header {
  height: 100vh;
  overflow: hidden;
}
/* Header Styling Ends */
/* TEXT SECTIONS STYLE STARTS  */
.text-section {
  margin-top: 14rem;
  margin-bottom: 15rem;
}
.text-section h1 {
  text-align: center;
}
.text-section h1 span:nth-child(1) {
  font-size: 3.1vw;
  font-weight: var(--ff-clashDisplay-regular);
  display: block;
}

.text-section h1 span:nth-child(2) {
  font-size: 6vw;
  font-weight: var(--ff-clashDisplay-bold);
}
.text-section-pill {
  display: grid;
  place-content: center;
  grid-auto-flow: column;
  gap: 1rem;
}
.text-section-pill img {
  width: 27vw;
  max-height: 150px;
  object-fit: cover;
  border-radius: 2rem;
}
/* TEXT SECTIONS STYLE ENDS  */

/* OUR event SECTION STARTS */

.our-event-card {
  height: 540px;
  width: 350px;

  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 1rem;
  color: var(--cf-neutral-white-100);
  background-position: center;
  overflow: hidden;
  position: relative;
}
.our-event-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Black overlay with 50% opacity */
  border-radius: inherit; /* Inherit the border-radius of the card */
  z-index: 0; /* Place it below the content */
}

.our-event-card * {
  position: relative;
  z-index: 1; /* Ensure content is above the overlay */
}

.our-event-card .date {
  display: inline;
  width: max-content;
  font-family: var(--ff-clashDisplay-medium);
  font-size: 1rem;
  padding: 10px 16px;
  border-radius: 30px;
  background-color: hsla(0, 0%, 100%, 0.2);
}
.our-event-card .location {
  font-family: var(--ff-clashDisplay-semibold);
  font-size: 1.5rem;
}
.our-event-card .description {
  font-family: var(--ff-clashDisplay-medium);
}

.our-event-card button {
  background-color: var(--cf-neutral-white-100);
  color: var(--cf-primary-400);
  font-family: var(--ff-clashDisplay-medium);
  font-size: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 3rem;
  margin-left: -4px;
}
.our-event-card button:hover {
  box-shadow: none;
  transform: none;
  outline: 2px solid var(--cf-primary-400);
  transition: 200ms;
  scale: 1.015;
}
.swiper-wrapper .swiper-slide:nth-child(1) .our-event-card {
  background-image: url("../Asset/our-work-card-1.jpg");
}
.swiper-wrapper .swiper-slide:nth-child(2) .our-event-card {
  background-image: url("../Asset/our-work-card-2.jpg");
}
.swiper-wrapper .swiper-slide:nth-child(3) .our-event-card {
  background-image: url("../Asset/our-work-card-3.jpg");
}
.swiper-wrapper .swiper-slide:nth-child(4) .our-event-card {
  background-image: url("../Asset/our-work-card-4.jpg");
}
/* OUR EVENT SECTION ENDS */
/* OUR SERVICE SECTION STARTS */
.service {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--ss-ms-l);
  border-bottom: 1px solid var(--cf-neutral-gray-400);
  cursor: pointer;
  transition: 500ms;
  margin-bottom: 4px;
}
.service p {
  max-width: 80ch;
  margin: 1rem 0rem;
  color: var(--cf-neutral-white-100);
  font-family: var(--ff-inter-regular);
  display: block;
  height: 0px;
  overflow: hidden;
  transition: height 300ms;
}
.service div {
  vertical-align: top;
}
/* .service div div {
  display: inline-block;
} */
.service span {
  font-family: var(--ff-clashDisplay-semibold);
  font-size: 2rem;
  color: var(--cf-primary-400);
  margin-right: var(--ss-ms-r);
}
.service-img {
  display: block;
  height: 0px;
  overflow: hidden;
  transition: height 300ms;
}
.service button {
  display: none;
  background-color: var(--cf-neutral-white-100);
  color: var(--cf-neutral-black-900);
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  justify-content: space-between;
  width: max-content;
}
.service button img {
  background-color: var(--cf-neutral-black-900);
  padding: 10px;
  border-radius: 40%;
}
.service h4 {
  display: inline;
  white-space: nowrap;
  transition: 300ms;
}
.service span {
  transition: 300ms;
}

/* OUR SERVICE SECTION ENDS */
/* GALLERY SECTION START  */
#reelButton {
  position: absolute;
  text-decoration: none;
  color: #fff;
  bottom: 0;
  right: 0;
  padding: 2em 1em;
  font-size: 16px;
}

.carousel {
  width: 100%;
  cursor: grab;
  overflow: hidden; /* Hide scrollbars */
}

.carousel-wrapper {
  white-space: nowrap;
  overflow-x: scroll; /* Allow scrolling */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.carousel-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
}

.carousel-item {
  display: inline-block;
  width: 40vw;
  padding: 3vw;
}

.carousel-item figure {
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
}

.carousel-item figure img {
  position: absolute;
  width: 100%;
  object-fit: cover;
}

.carousel-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20vw;
  height: 2px;
  margin: 2em;
  background: rgba(87, 94, 32, 0.1);
}

.carousel-progress-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--cf-primary-400);
  transform: scaleX(0);
  transform-origin: 0% 0%;
}
/* GALLERY SECTION END  */

/* CELEBRATE SECTION STARTS  */
.celebrate {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.celebrate p {
  color: var(--cf-neutral-gray-400);
  max-width: 40ch;
}
.celebrate button {
  margin: 0;
  margin-top: 2rem;
}
.celebrate-img {
  position: relative;
}
.celebrate-img img {
  max-height: 570px;
  max-width: 460px;
  object-fit: cover;
  border-radius: 1rem;
}
.celebrate-data {
  position: absolute;
  z-index: 2;
  background-color: #cac9b7;
  border-radius: 1rem;
  padding: 2rem;
  display: grid;
  gap: 1rem;
  top: 25%;
  left: -40%;
}
.celebrate-data #C1 {
  border-bottom: 1px solid var(--cf-neutral-gray-400);
  padding-bottom: 1.5rem;
}
.celebrate-data p {
  padding-top: 1rem;
}
/* CELEBRATE SECTION ENDS  */
/* TESTIMONIAL SECTION STARTS  */
.testomonial-phone {
  display: none;
}

.testimonial-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  gap: 1rem;
}
.card {
  /* max-width: 300px; */
  background-color: var(--cf-secondary-400);
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
}
.card .profile {
  margin-bottom: 1rem;
}
.card .date {
  margin-top: 1rem;
}
.card .profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.card .profile img {
  border-radius: 50%;
}
.outter-card {
  display: inline-block;
  padding: 2rem;
  border-radius: 1rem;
  background-color: rgba(113, 115, 66, 0.3);
}

.card .profile {
  font-family: var(--ff-inter-bold);
}
.card p {
  font-family: var(--ff-inter-regular);
  font-size: 12px;
}
.card .date {
  font-family: var(--ff-inter-regular);
  font-size: 10px;
  color: var(--cf-neutral-300);
}
#testomonial-img {
  border-radius: 1rem;
  overflow: hidden;
  max-height: 500px;
  object-fit: cover;
}
.testomonial-outter-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
/* TESTIMONIAL SECTION ENDS  */
/* FAQ SECTION STARTS  */
.faq-container {
  display: flex;
  justify-content: space-between;
  padding: var(--ss-ps-xl);
  font-family: var(--ff-clashDisplay-semibold);
  font-size: 1.5rem;
  position: relative;
  border-bottom: 1px solid var(--cf-neutral-gray-400);
}
.last-faq-container {
  border-bottom: none;
}
.faq-container .icon {
  font-size: 4rem;
  font-family: var(--ff-clashDisplay-regular);
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cf-neutral-white-100);
  border-radius: 50%;
  line-height: 95%;
  position: absolute;
  right: 3rem;
}
.faq-container p {
  max-width: 80ch;
}
.faq-text {
  display: flex;
  gap: var(--ss-ms-s);
}
.faq-outter-container {
  background-color: #f3f5f6;
  border-radius: 1rem;
  overflow: hidden;
}
.faq-active {
  background-color: var(--cf-primary-400);
  color: var(--cf-neutral-white-100);
  transition: 300ms;
}
.faq-container p {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.faq-active p {
  color: var(--cf-neutral-white-100);
  margin-top: 1rem;
}

/* FAQ SECTION ENDS  */
/* CONTACT US SECTION STARTS  */
.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-us-img {
  background-color: var(--cf-neutral-200);
  width: min-content;
  padding: 2rem;
  border-radius: 1rem;
}
.contact-us-img *:not(:last-child) {
  margin-bottom: 1rem;
}
.contact-us-img .label-2 {
  color: var(--cf-neutral-300);
}
.contact-us-img .label-1 {
  color: var(--cf-neutral-gray-400);
}

.contact-us-img-container {
  min-width: 30vw;
  height: 400px;
  border-radius: 2rem;
  background-image: url("../Asset/contact-us.jpg");
  display: grid;
  place-content: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
#mlb2-18022563.ml-form-embedContainer .ml-form-embedWrapper {
  width: 100% !important;
  max-width: none !important;
}
#mlb2-18022563.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  input,
#mlb2-18022563.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  input,
#mlb2-18022563.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  textarea {
  padding: 20px !important;
}
/* CONTACT US SECTION ENDS  */
/* FOOTER SECTION STARTS */
footer {
  background-color: #cac9b6;
  text-align: center;
  white-space: nowrap;
}

footer ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-right: 0px;
}
footer ul li a {
  color: var(--cf-primary-400);
}
footer ul:nth-child(1) {
  padding-top: var(--ss-ms-xl);
}

footer hr {
  border: 1px solid var(--cf-primary-400);
}

.footer-more-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  gap: 1rem;
  padding: 2rem;
  font-family: var(--ff-inter-regular);
  color: var(--cf-primary-400);
}

.luxe-studio-footer-logo img {
  width: 100%;
}
/* FOOTER SECTION ENDS */
/* Animation */
/* text animation  */
.text {
  text-shadow: 0 1.1em 0 currentcolor;
  display: inline-block;
  overflow: hidden;
}
/* Responsive settings  */
@media (min-width: 1100px) {
  /* service hover state  */
  .service:hover {
    background: linear-gradient(
      180deg,
      #717342 0%,
      #717342 43.5%,
      #959855 100%
    );
    padding: 2rem 4rem;
    border-radius: 1rem;
    overflow: hidden;
  }
  .service:hover .service-img {
    height: 200px;
  }
  .service:hover.service p {
    height: min-content;
  }
  .service:hover.service h4 {
    color: var(--cf-neutral-white-100);
  }
  .service:hover.service span {
    color: var(--cf-neutral-white-100);
  }
  .service:hover.service button {
    display: flex;
  }
  .contact-us {
    flex-direction: row;
  }
  .celebrate {
    flex-direction: row;
  }
}

@media (max-width: 1200px) {
  header h3 {
    font-size: 100px;
  }
  .slider-content {
    height: 120px;
  }
  .celebrate-img img {
    height: 370px;
    width: 360px;
  }
}
@media (max-width: 834px) {
  :root {
    /* size */
    /* basic  */
    --ss-global-container: 0% 2%;
    /* margin  */
    --ss-ms-xl: var(--ss-ms-xl-t);
    --ss-ms-l: var(--ss-ms-l-t);
    --ss-ps-xl: var(--ss-ps-xl-p);
  }
  header h3 {
    font-size: 80px;
  }
  .slider-content {
    height: 100px;
  }
  h2 {
    font-size: 3rem;
  }

  .label-4 {
    font-size: 6rem;
    transform: translateY(-42px);
    margin-bottom: var(--ss-ms-l);
  }
  /* nav menu  */
  /* Hidden by default */
  .nav-menu {
    position: fixed;
    top: -120%;
    left: 0;
    height: 100vh;
    width: 100%;
    border-radius: 0 0 16px 16px;
    transition: top 0.7s ease-in-out;
    z-index: 10;

    display: grid;
    place-content: center;
    background-color: #d4d973;
    border-radius: 0px 0px 16px 16px;
  }

  .nav-active {
    top: 0;
  }

  .nav-menu ul li {
    display: block;
    text-align: center;
    font-size: 4rem;
  }
  .nav-menu ul li a {
    font-family: var(--ff-clashDisplay-medium);
  }
  .nav-menu ul {
    margin-bottom: 3rem;
  }
  #nav-icon4 {
    display: block;
    z-index: 11;
  }
  .service h4 {
    font-size: 2.5rem;
  }
  .service span {
    font-size: 1.5rem;
  }
  .service-img {
    display: none;
  }
  .celebrate-img img {
    max-height: 470px;
    max-width: 360px;
    margin-left: 120px;
  }
  .celebrate {
    flex-direction: column;
    gap: 3rem;
  }
  .celebrate-data {
    left: 15%;
    width: 180px;
  }
  #testomonial-img {
    display: none;
  }
  .faq-container .icon {
    display: none;
  }
}
@media (max-width: 550px) {
  .testomonial-phone {
    display: block;
  }
  .testomonial-outter-container {
    display: none;
  }
  .faq-container .number {
    display: none;
  }
}
@media (max-width: 430px) {
  :root {
    /* size */
    /* margin  */
    --ss-ms-xl: var(--ss-ms-xl-p);
    --ss-ms-l: var(--ss-ms-l-p);
    --ss-ms-r: var(--ss-ms-r-p);
    /* padding */
    --ss-ps-l: var(--ss-ps-l-p);
  }
  header h3 {
    font-size: 40px;
  }
  .slider-content {
    height: 80px;
    left: 0.5em;
    width: 95vw;
    overflow: hidden;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.875rem;
  }
  .label-1 {
    font-size: 0.875rem;
  }
  .label-3 {
    font-size: 1.5rem;
  }
  .label-4 {
    font-size: 3rem;
    transform: translateY(-23px);
  }
  #main-logo {
    margin-left: -2rem;
  }
  #nav-icon4 {
    margin-right: 2%;
  }

  #nav-icon4,
  #main-logo {
    scale: 0.8;
  }
  .text-section h1 span:nth-child(1) {
    font-size: 4.2vw;
  }
  .text-section h1 span:nth-child(2) {
    font-size: 8.2vw;
  }
  .text-section-pill img {
    width: 36vw;
    max-height: 30px;
  }
  .text-section-pill {
    gap: 0.3rem;
  }
  .service h4 {
    font-size: 2rem;
  }
  .service span {
    font-size: 1.2rem;
  }
}
